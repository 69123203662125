import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Layout from "../components/Layout";

const StripePage = ({ email, usuarioId, authResolved, loggedIn }) => {
	return (
		<Layout>
			{authResolved && (
				<div>
					<h1>Stripe</h1>
					<a
						href={`https://buy.stripe.com/4gwaHI97G8mF6Q028b?prefilled_email=${email}&client_reference_id=${usuarioId}`}
					>
						Pagar Premium Monthly
					</a>
					<a
						href={`https://buy.stripe.com/aEU8zAdnW9qJ0rC6ou?prefilled_email=${email}&client_reference_id=${usuarioId}`}
					>
						Pagar Premium Yearly
					</a>
				</div>
			)}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		email: state.auth.usuario?.email,
		usuarioId: state.auth.usuario?.id,
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
	};
};

export default connect(mapStateToProps)(StripePage);
